import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy'
import styled from 'styled-components';
import close from '../../images/XXX.svg'
import { Modal, Button } from 'antd';
import { lighten } from 'polished'

interface Props {
  visible: boolean;
  onClose(): any;
  url: string;
}

const ModalBody = styled.div`
  width: 100%;
  background-color: rgb(13, 14, 17);
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 64px 64px 0 0;
  @media screen and (max-width: 1750px) {
    padding: 12px 12px 0 0;
  }
`

const CloseButton = styled(Button)`
  background-color: rgba(255, 255, 255, 0.12);
  height: 48px;
  width: 48px;
  border: 0;
  transition: background-color 100ms ease-in-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
`
const maxWidth = 1500;
const aspectRatio = (9 / 16);
const padding = 12;
const VideoModal: React.FC<Props> = ({ visible, onClose, url }) => {
  const [dimens, setDimens] = useState({ width: maxWidth, height: (aspectRatio) * maxWidth });
  const [videoDimens, setVideoDimens] = useState({ width: maxWidth, height: (aspectRatio) * maxWidth });

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener('resize', handleResize);
      handleResize();
    }
    return () => {
      if (typeof window !== undefined) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, []);
  const handleResize = () => {
    if (typeof window !== undefined) {
      console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
      setDimens(({
        width: window.innerWidth,
        height: window.innerHeight
      }));

      let width = window.innerWidth >= maxWidth ? maxWidth : window.innerWidth - (padding * 2)
      let height = window.innerWidth >= maxWidth ? (aspectRatio * maxWidth) : (aspectRatio * window.innerWidth) - (padding * 2);
      if (window.innerHeight < height) {
        height = window.innerHeight - padding * 2;
        width = (1 / aspectRatio) * window.innerHeight - padding * 2;
      }
      setVideoDimens(({
        width: width,
        height: height
      }));
    }
  }
  return (
    <Modal
      centered={false}
      visible={visible}
      onCancel={onClose}
      footer={null}
      closable={true}
      closeIcon={<div style={{ display: 'none' }} />}
      mask={visible}
      width={dimens.width}
      keyboard={true}
      destroyOnClose
      bodyStyle={{
        padding: 0,
      }}
      maskStyle={{
        backgroundColor: "rgb(13, 14, 17)",
        display: "block",
        opacity: 1
      }}
      style={{ top: 0, padding: 0, margin: 0, maxWidth: "100vw" }}
    >
      <ModalBody onClick={onClose} style={{ height: dimens.height, paddingRight: padding, paddingLeft: padding }}>
        <ReactPlayer
          url={url}
          width={videoDimens.width}
          height={videoDimens.height}
          config={{
            youtube: {
              playerVars: {
                controls: 1
              }
            }
          }}
        />
      </ModalBody>
      <CloseButtonContainer>
        <CloseButton onClick={onClose} type="primary" shape="circle" size={"large"}>
          <img src={close} style={{ height: 14, width: 14 }} />
        </CloseButton>
      </CloseButtonContainer>
    </Modal>
  );
};

export default VideoModal;