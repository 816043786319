import { Row } from 'antd';
import React from 'react';
import { StoreCol, StoreIcon } from './styled';
import app_store from "../../images/app_store.svg";
import google_play from "../../images/google_play.svg";

interface Props {
  align?: any;
  justify?: any;
  style?: React.CSSProperties;
}

const AppButtons: React.FC<Props> = ({ align, justify, style }) => {
  return (
    <Row type="flex" align={align ? align : "middle"} justify={justify ? justify : "center"} gutter={18} style={{ marginTop: 18 }}>
      <StoreCol>
        <a
          href='https://itunes.apple.com/ca/app/dereader/id1458310008?mt=8'
          target='_blank'
          rel='noopener'
          title="App Store"
        >
          <StoreIcon
            style={style}
            src={app_store}
            alt='App Store - DeReader'
          />
        </a>
      </StoreCol>
      <StoreCol>
        <a
          href='https://play.google.com/store/apps/details?id=com.latticestudio.dereader'
          target='_blank'
          rel='noopener'
          title="Google Play Store"
        >
          <StoreIcon
            style={style}
            src={google_play}
            alt='Play Store - DeReader'
          />
        </a>
      </StoreCol>
    </Row>
  )
}

export default AppButtons;